<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Stock Activos</h3>
        </div>
        <div class="card-body">
          <div class="row d-flex align-items-end">
            <div class="form-group col-md-3">
              <label>Tipo Produto</label>
              <select
                required
                type="text"
                class="form-control"
                v-model="filtro.tipo_produto_id"
              >
                <option value="">Seleccione:</option>
                <option
                  v-for="lista in lista_tipos_produto"
                  :key="lista.id"
                  :value="lista.id"
                >
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label>Substancia</label>
              <select
                required
                type="text"
                class="form-control"
                v-model="filtro.substancias_id"
              >
                <option value="">Seleccione:</option>
                <option
                  v-for="lista in lista_substancias"
                  :key="lista.id"
                  :value="lista.id"
                >
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label>Tipo Clase </label>
              <select
                required
                type="text"
                class="form-control"
                v-model="filtro.tipoClasse_id"
              >
                <option value="">Seleccione:</option>
                <option
                  v-for="lista in lista_tipos_classe"
                  :key="lista.id"
                  :value="lista.id"
                >
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <!-- <div class="form-group col-md-2">
              <label class="col-md-12">Laboratorio</label>
              <select
                required
                type="text"
                class="form-control"
                v-model="filtro.laboratorio_id"
              >
                <option value="">Selecione:</option>
                <option
                  v-for="lista in lista_laboratorios"
                  :key="lista.id"
                  :value="lista.id"
                >
                  {{ lista.nome }}
                </option>
              </select>
            </div> -->
            <div class="form-group col-md-1">
              <button class="btn btn-success" @click="pesquisar()">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </button>
            </div>
            <div class="form-group col-md-2">
              <button class="btn btn-info" @click="gerarExcel()">
                <i class="fa fa-print m-0 p-0"></i>
              </button>
            </div>
          </div>
          <b-row align-h="end"> </b-row>

          <b-table
            :fields="[
              'lote',
              'produto',
              'laboratorio',
              'tipo_produto',
              'validade',
              'data_recebimento',
              'estoque_fracao',
              'estoque_quantidade',
              'descricao',
              // 'fila',
              // 'coluna',
              'acoes',
            ]"
            :items="lista_estoque_produtos"
            :per-page="perPage"
            :current-page="currentPage"
            id="produto-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
              pt-1
            "
            show-empty
            empty-text="Ningun registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <!-- <button
                  v-show="lista_permissoes_filial.u_Sala"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-eye text-primary"></i>
                </button> -->

                <!-- <button
                  v-show="lista_permissoes_filial.lock_Sala"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button> -->
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_estoque_produtos.length"
            :per-page="perPage"
            aria-controls="produto-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {
      filtro: {
        substancias_id: null,
        tipoClasse_id: null,
        tipo_produto_id: null,
        data_inicio: null,
        data_fim: null,
      },
      pesquisa: {
        tipo: null,
        texto: "",
      },
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatorio" }]);
  },
  created() {
    // this.listar_produtos();
    this.listar_substancias();
    this.listar_laboratorios();
    this.listar_tipos_classe();
  },
  computed: {
    lista_substancias() {
      return this.$store.state.substancia.lista_substancias;
    },
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto;
    },

    lista_estoque_produtos() {
      return this.$store.state.produto.lista_estoque_produtos;
    },
    lista_laboratorios() {
      return this.$store.state.laboratorio.lista_laboratorios;
    },
    lista_tipos_classe() {
      return this.$store.state.tipoClasse.lista_tipos_classe;
    },

    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },

    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    
  },
  methods: {
    async listar_produtos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("produto/pesquisar_estoques_ativos", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("produto/listar_produto", "");
          // localStorage.removeItem('produto');
        });
    },
    async listar_substancias() {
      await this.$store.dispatch("substancia/listar_substancias");
      this.$store.dispatch("produto/listar_tipos_produto");
    },
    async listar_laboratorios() {
      this.$store.dispatch("laboratorio/listar_laboratorios");
    },
    async listar_tipos_classe() {
      this.$store.dispatch("tipoClasse/listar_tipos_classe");
    },

    atualizar(value) {
      this.$router.push({
        name: "produtoDados",
        params: { produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },

    async pesquisar() {
      // this.$store.dispatch("configEmpresa/MudarPreloader", true);
      console.log(this.filtro);
      await this.$store.dispatch(
        "produto/pesquisar_estoques_ativos",
        this.filtro
      );
      // this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
    async gerarExcel() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      console.log(this.filtro);
      await this.$store.dispatch("produto/pesquisar_estoques_ativos_excel",this.filtro);
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>